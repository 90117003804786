var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    [
      _c(
        "transparent-header",
        [
          _c("pagination", {
            class: {
              "pagination-display": _vm.isQuestionDetailsShowing
            },
            attrs: { totalPages: _vm.totalPages, currentPage: _vm.currentPage },
            on: { update: _vm.updatePage }
          })
        ],
        1
      ),
      _c(
        "feed-layout",
        {
          scopedSlots: _vm._u([
            {
              key: "list",
              fn: function() {
                return [
                  _c("div", [
                    _c("h4", { staticClass: "question-card-list-title" }, [
                      _vm._v("Curiosity Feed")
                    ]),
                    _vm.questionList
                      ? _c(
                          "div",
                          _vm._l(_vm.questionList, function(item, index) {
                            return _c("question-card", {
                              key: item.question.id,
                              staticClass: "question-card-item",
                              attrs: {
                                question: item.question,
                                author: item.author,
                                metadata: item.metadata
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showQuestion(index, $event)
                                }
                              }
                            })
                          }),
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c("v-progress-circular", {
                              attrs: { color: "inqliPurple", indeterminate: "" }
                            })
                          ],
                          1
                        )
                  ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.isQuestionDetailsShowing,
            callback: function($$v) {
              _vm.isQuestionDetailsShowing = $$v
            },
            expression: "isQuestionDetailsShowing"
          }
        },
        [
          [
            _c(
              "div",
              [
                _c("question-detail", {
                  key: _vm.questionDetailKey,
                  staticClass: "question-detail-content",
                  attrs: {
                    questionDetail: _vm.currentShowQuestion,
                    closeCard: _vm.closeQuestionDetailCard,
                    isLoadingQuestionDetail: _vm.isLoadingQuestionDetail
                  },
                  on: { reload: _vm.getQuestionDetail }
                })
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }