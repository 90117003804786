<template>
  <layout>
    <transparent-header>
      <pagination
        @update="updatePage"
        :totalPages="totalPages"
        :currentPage="currentPage"
        :class="{
          'pagination-display': isQuestionDetailsShowing,
        }"
      />
    </transparent-header>
    <feed-layout v-model="isQuestionDetailsShowing">
      <template v-slot:list>
        <div>
          <h4 class="question-card-list-title">Curiosity Feed</h4>
          <div v-if="questionList">
            <question-card
              :question="item.question"
              :author="item.author"
              :metadata="item.metadata"
              v-for="(item, index) in questionList"
              :key="item.question.id"
              @click="showQuestion(index, $event)"
              class="question-card-item"
            />
          </div>
          <div v-else>
            <v-progress-circular
              color="inqliPurple"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </template>
      <template>
        <div>
          <question-detail
            class="question-detail-content"
            :questionDetail="currentShowQuestion"
            :closeCard="closeQuestionDetailCard"
            :isLoadingQuestionDetail="isLoadingQuestionDetail"
            :key="questionDetailKey"
            @reload="getQuestionDetail"
          />
        </div>
      </template>
    </feed-layout>
  </layout>
</template>
<script>
import Pagination from "../app/components/Pagination.vue";
import DefaultHeader from "../app/components/headers/DefaultHeader.vue";
import QuestionDetail from "./components/question-detail/QuestionDetail.vue";
import QuestionCard from "./components/question-card/QuestionCard.vue";
import sendRequest from "../app/utils/send-request-helper";
import DefaultLayout from "../app/components/layouts/DefaultLayout.vue";
import FeedLayout from "./components/FeedLayout.vue";

export default {
  name: "CuriosityFeed",
  metaInfo: {
    title: "Curiosity Feed",
  },
  components: {
    "transparent-header": DefaultHeader,
    pagination: Pagination,
    "question-card": QuestionCard,
    "question-detail": QuestionDetail,
    layout: DefaultLayout,
    "feed-layout": FeedLayout,
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 10,
      limit: 10,
      feedData: null,
      isQuestionDetailsShowing: false,
      currentQuestionIndex: 0,
      isLoadingQuestionDetail: true,
      currentQuestionDetail: null,
    };
  },
  computed: {
    questionDetailKey() {
      // This is to make sure the question detail card is always updated
      // after question detail response is update with metadata (liked, bookmarked, etc.)
      if (!this.isQuestionDetailsShowing) return 0;
      if (this.currentQuestionDetail) {
        const isLiked = this.currentQuestionDetail.question.is_liked ? 1 : 0;
        return this.currentQuestionDetail.question.id + isLiked;
      }
      return this.currentShowQuestion.question.id;
    },
    currentShowQuestion() {
      if (!this.isQuestionDetailsShowing) return {};
      if (this.currentQuestionDetail) {
        return this.currentQuestionDetail;
      }
      return this.questionList[this.currentQuestionIndex];
    },
    questionList() {
      return this.feedData ? this.feedData : null;
    },
  },
  async mounted() {
    await this.getFeed();
  },
  beforeDestroy() {
    this.clearData();
  },
  methods: {
    clearData() {
      this.feedData = null;
      this.currentReplyList = null;
    },
    showQuestion(questionIndex) {
      this.isQuestionDetailsShowing = true;
      this.currentQuestionIndex = questionIndex;
      this.getQuestionDetail();
    },
    closeQuestionDetailCard() {
      this.isQuestionDetailsShowing = false;
    },
    async updatePage(page) {
      this.currentPage = page;
      await this.getFeed();
    },
    async getFeed() {
      this.isQuestionDetailsShowing = false;
      try {
        const data = await sendRequest({
          url: `/pages/feed?page=${this.currentPage}&limit=${this.limit}&sort=create_date&order=desc`,
          method: "GET",
          isAuth: false,
        });
        this.feedData = data.feed;
        this.totalPages = data.total_pages > 0 ? data.total_pages : 1;
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting the feed"
        );
      }
    },
    async getQuestionDetail() {
      try {
        this.currentQuestionDetail = null;
        this.isLoadingQuestionDetail = true;
        const data = await sendRequest({
          url: `/pages/question-details/${this.currentShowQuestion.question.id}`,
          method: "GET",
        });
        this.currentQuestionDetail = data;
        this.isLoadingQuestionDetail = false;
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting the question details"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.question-card-list {
  padding: 0 10px;
  max-width: 800px;
  width: 60%;
}
.question-card-item {
  margin-bottom: 12px;
}
.question-card-list-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 15px;
}
@media (max-width: $breakpoint-tablet-large) {
  .pagination-display {
    display: none;
  }
}
</style>
